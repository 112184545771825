<template>
    <v-dialog
      v-model="open"
      content-class="tw-w-full tw-max-w-7xl 2xl:tw-max-w-screen-lg tw-h-auto tw-bg-transparent tw-shadow-none tw-overflow-hidden"
    >
        <vimeo-player v-if="type == 'vimeo'" ref="player" :video-url="linkvideo" :options="{ autoplay: true }" class="tw-aspect-w-16 tw-aspect-h-9" />
        <LazyYoutube v-if="type == 'youtube'" ref="youtube" :src="linkvideo" aspect-ratio="16:9" :autoplay="true" />
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'vimeo',
        },
        linkvideo: {
            type: String,
            default: '',
        },
    },
    computed: {
        open: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>