<template>
  <renderless-layout :full-screen="true">
    <!-- <div class="tw-bg-white tw-relative" :style="colors"> -->
    <RegistryLayout>
      <div class="tw-relative">
        <RegistryHeader :slug="slug" />
        <main v-if="!current_landing.has_custom_landing">
          <!-- <template v-for="bannerSection in processedBannerSections">
            <RegistryHomeBannerWithText
              v-if="bannerSection.name === BannerTypeKeys.banner_with_text"
              :key="bannerSection.name"
              :slug="slug"
              :banner-section="bannerSection"
            />
            <RegistryHomeBannerSimpleImage
              v-if="bannerSection.name === BannerTypeKeys.simple_image"
              :key="bannerSection.name"
              :slug="slug"
              :banner-section="bannerSection"
            />
          </template> -->
          <!-- Header -->
          <div
            v-if="false"
            class="tw-relative tw-w-full tw-bg-gray-900 tw-pt-10 xl:tw-aspect-w-16 xl:tw-aspect-h-7"
          >
            <div class="tw-absolute tw-inset-0">
              <!-- :src="require('@/assets/bg_login_bizzdome.jpg')" -->
              <!-- tw-aspect-w-16 tw-aspect-h-5 -->
              <img
                aria-hidden="true"
                class="tw-h-full tw-w-full tw-object-cover"
                :src="current_landing.hero_image_url"
                alt="Office"
              />
              <!-- <div
                class="tw-absolute tw-inset-0 tw-bg-gradient-to-r tw-from-landing-primary tw-via-landing-primary tw-to-transparent"
                style="mix-blend-mode: multiply"
              /> -->
              <!-- tw-to-landing-primary/30 -->
              <div
                class="tw-absolute tw-inset-0 tw-bg-gradient-to-b tw-from-landing-primary tw-via-landing-primary tw-to-transparent tw-mix-blend-multiply"
              />
            </div>
            <div
              class="lg:px-8 tw-relative tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-16 sm:tw-py-24 sm:tw-px-6 lg:tw-py-24 xl:tw-absolute"
            >
              <div class="tw-mx-auto tw-max-w-7xl">
                <h1
                  class="tw-text-center tw-text-4xl tw-font-extrabold tw-tracking-tight tw-text-white sm:tw-text-5xl md:tw-text-6xl lg:tw-text-8xl"
                  v-html="current_landing.hero_title"
                />
                  <!-- <span class="tw-block tw-text-white">Take control of your</span>
                  <span class="tw-block tw-text-indigo-200">customer support</span> -->

                  <!-- {{  }} -->
                <!-- </h1> -->
                <p
                  class="tw-mx-auto tw-mt-6 tw-max-w-lg tw-text-center tw-text-xl tw-text-indigo-200 sm:tw-max-w-3xl"
                  v-html="current_landing.hero_description"
                />
                  <!-- {{ current_landing.hero_description }} -->
                <!-- </p> -->
                <div
                  class="tw-mx-auto tw-mt-10 tw-max-w-sm sm:tw-flex sm:tw-max-w-none sm:tw-justify-center"
                >
                  <div
                    class="tw-space-y-4 sm:tw-mx-auto sm:tw-inline-grid sm:tw-grid-cols-2 sm:tw-gap-5 sm:tw-space-y-0"
                  >
                    <div>
                      <v-btn
                        rounded
                        color="white"
                        class="tw-w-full tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 sm:tw-w-auto"
                        :to="toRegister"
                      >
                        Registrate
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        color="black"
                        dark
                        rounded
                        class="tw-w-full tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case tw-shadow-md focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 sm:tw-w-auto"
                        :to="toLogin"
                      >
                        Iniciar Sesion
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-for="section in processedSections">
            <RegistrySlider
              v-if="
                [
                  SectionTypeKeys.main_slider,
                  SectionTypeKeys.slider,
                  SectionTypeKeys.slider_two,
                ].includes(section.name) && section.is_active
              "
              :key="section.name"
              :class="[
                section.name == SectionTypeKeys.main_slider ? 'bz-landing-main_slider' : '',
                section.name == SectionTypeKeys.slider ? 'bz-landing-slider_one' : '',
                section.name == SectionTypeKeys.slider_two ? 'bz-landing-slider_two' : '',
              ]"
              :section="section"
              :slug="slug"
            />
            <RegistryCountdown
              v-if="section.name === SectionTypeKeys.countdown_timer && section.is_active"
              :key="section.name"
              :section="section"
              class="bz-landing-countdown_timer"
            />
            <RegistryMultiButtons
              v-if="section.name === SectionTypeKeys.multi_buttons && section.is_active"
              :key="section.name"
              :section="section"
              :slug="slug"
              class="bz-landing-multi_buttons"
            />
            <RegistryHomeHero
              v-if="section.name === SectionTypeKeys.cta && section.is_active"
              :key="section.name"
              :section="section"
              class="bz-landing-home_hero"
            />
            <RegistryHomeLogos
              v-if="
                section.name === SectionTypeKeys.sponsor && section.is_active
              "
              :key="section.name"
              class="bz-landing-sponsor_logos"
              :section="section"
            />
            <RegistryHomeCalAct
              v-if="
                section.name === SectionTypeKeys.activities && section.is_active
              "
              :key="section.name"
              class="bz-landing-activities_and_calendar"
              :section="section"
            />
            <RegistryHomeImageRatio
              v-if="
                [
                  SectionTypeKeys.image,
                  SectionTypeKeys.image_two,
                ].includes(section.name) && section.is_active
              "
              :key="section.name"
              :class="[
                section.name == SectionTypeKeys.image ? 'bz-landing-image_ratio' : '',
                section.name == SectionTypeKeys.image_two ? 'bz-landing-image_ratio_two' : ''
              ]"
              :section="section"
            />
            <!-- <RegistryHomeImageRatio
              v-if="section.name === SectionTypeKeys.image && section.is_active"
              :key="section.name"
              class="bz-landing-image_ratio"
              :section="section"
            /> -->
          </template>
        </main>
        <main v-else v-html="current_landing.custom_landing_html" />
        <SimpleCalendarModal v-model="simple_calendar_modal" />
      </div>
      <!-- Button tutorial -->
      <div v-if="current_landing.has_tutorial" class="tw-fixed tw-bottom-4 tw-right-4">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              color="red"
              elevation="7"
              fab
              class="pulse tw-text-white"
              v-bind="attrs"
              v-on="on"
              @click="openTutorial = true"
            >
              <v-icon>play_arrow</v-icon>
            </v-btn>
          </template>
          <span>Tutorial</span>
        </v-tooltip>
      </div>
      <RegistriesLandingsTutorial v-model="openTutorial" :type="current_landing.type_video_tutorial" :linkvideo="current_landing.url_tutorial"/>
    </RegistryLayout>
    <!-- </div> -->
  </renderless-layout>
</template>

<script>
import RegistryLayout from './RegistryLayout.vue'
import RegistryHomeCalAct from './RegistryHomeCalAct.vue'
import RegistryHomeHero from './RegistryHomeHero.vue'
import RegistryHeader from './RegistryHeader.vue'
import RegistryHomeLogos from './RegistryHomeLogos.vue'
import RegistryHomeImageRatio from './RegistryHomeImageRatio.vue'
// import RegistryHomeBannerWithText from './RegistryHomeBannerWithText.vue'
// import RegistryHomeBannerSimpleImage from './RegistryHomeBannerSimpleImage.vue'
import { mapState } from 'vuex'
import { hexToRgbComma } from '@/utils/hexToRgb'
import { SectionTypeKeys, BannerTypeKeys } from '@/enums/SectionTypeKeys'
import SimpleCalendarModal from './SimpleCalendarModal.vue'
import { mapFields } from '@/helpers'
import RegistrySlider from './RegistrySlider.vue'
import RegistryCountdown from './RegistryCountdown.vue'
import RegistryMultiButtons from './RegistryMultiButtons.vue'
import RegistriesLandingsTutorial from '@/components/RegistriesModals/RegistriesLandingsTutorial.vue'

export default {
  components: {
    RegistryLayout,
    RegistryHomeImageRatio,
    RegistryHomeCalAct,
    RegistryHomeHero,
    RegistryHomeLogos,
    RegistryHeader,
    // RegistryHomeBannerSimpleImage,
    // RegistryHomeBannerWithText,
    SimpleCalendarModal,
    RegistrySlider,
    RegistryCountdown,
    RegistryMultiButtons,
    RegistriesLandingsTutorial,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    SectionTypeKeys,
    BannerTypeKeys,
    openTutorial: false,
  }),
  metaInfo() {
    return {
      title: this.registry.label,
      meta: [
      {
          vmid: 'og:title',
          property: 'og:title',
          content: this.registry.label, // Título del evento para Open Graph
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.registry.description, // Descripción del evento
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.current_landing.logo_url_sm, // URL del logo del evento para Open Graph
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.href, // URL del evento
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: this.registry.label, // Título para Twitter
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: this.registry.description, // Descripción para Twitter
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: this.current_landing.logo_url_sm, // Imagen para Twitter
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image', // Tipo de tarjeta de Twitter
        },
      ],
    }
  },
  head() {
    return {
      title: {
        inner: this.registry.label,
      },
      style: [
        {
          type: 'text/css',
          inner: this.current_landing.custom_css,
        },
      ],
    }
  },
  computed: {
    ...mapFields({
      fields: ['simple_calendar_modal'],
      base: ['registry', 'show'],
      mutation: 'registry/setShow',
    }),
    colors() {
      return {
        '--landing_ui_color': hexToRgbComma(this.current_landing.ui_color),
        '--landing_ui_contrast': hexToRgbComma(
          this.current_landing.ui_contrast,
        ),
      }
    },
    current_landing() {
      const landing = this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
      if (!landing) {
        return {}
      }
      return landing
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),

    toRegister() {
      return {
        name: 'registry.register',
        params: { slug: this.slug },
      }
    },
    toLogin() {
      return {
        name: 'registry.login',
        params: { slug: this.slug },
      }
    },
    processedSections() {
      if (!this.current_landing) {
        return []
      }
      if (!this.current_landing.sections) {
        return []
      }
      return this.current_landing.sections.map((row) => {
        return {
          ...row,
        }
      })
    },
    processedBannerSections() {
      if (!this.current_landing) {
        return []
      }
      if (!this.current_landing.banner_sections) {
        return []
      }
      return this.current_landing.banner_sections
        .filter((row) => row.is_active)
        .map((row) => {
          return {
            ...row,
          }
        })
    },
  },
  destroyed() {
    // TODO: store path in state
    console.log('destroyed path')
    this.$store.commit('registry/setPath', {link:'', params: ''})
  },
}
</script>

<style>
/* Style Global Slider */
.v-application ul,
.v-application ol {
  padding-left: 0;
}
.slick-dots {
  bottom: 5px;
}
.slick-prev,
.slick-next {
  z-index: 1;
}
.slick-prev {
  left: 20px;
}
.slick-next {
  right: 20px;
}
.overlay_image_link {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.slide-ot-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s infinite;
}
</style>
